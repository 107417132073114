<template>
  <div class="game-item">
    <img :src="game.icon" :alt="game.title" class="game-logo" />
    <div class="game-details">
      <h3 class="game-name">{{ game.title }}</h3>
     <div class="submission">
       <div class="submission-count" @click="handleClick(game)">详细评测（{{ game.submitNum }}/2）</div>
       <div v-if="game.GameComment&&game.GameComment==1" class="submission-count" @click="handleZongHeClick(game)">综合评测</div>
     </div>
    </div>
  </div>
  <RemindPP ref="customModal">
<!--    <template v-slot:header>
      这是提示标题
    </template>-->
    <template v-slot:body>
      此游戏今年的详细评测机会已用完
    </template>
  </RemindPP>
</template>

<script>
import RemindPP from "@/components/RemindPP";

export default {
  name: 'GameItem',
  components: {
    RemindPP
  },
  props: {
    game: {
      type: Object,
      required: true,
    },
  },
  methods:{
    handleClick(game) {
      if (game.submitNum > 1) {
        this.$refs.customModal.openModal();
      } else {
        this.$router.push({ name: 'GameFAQPage', params: { gameId: game.id,gameName:game.title } });
      }
    },
    handleZongHeClick(game) {
        this.$router.push({ name: 'ComprehensiveEvaluation', params: { gameId: game.id,gameName:game.title } });
    }
  }
};
</script>

<style scoped>
.game-item {
  display: flex; /* 使用Flexbox布局 */
  align-items: center; /* 垂直居中对齐 */
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 20px ; /* 添加一些内边距 */
  background: linear-gradient(to bottom, rgba(216, 255, 169, 0.34), rgba(53, 195, 93, 0.5));
}

.game-logo {
  width: 80px;
  height: 80px;
  margin-right: 15px; /* 添加一些右边距 */
  border-radius: 8px;
}

.game-details {
  flex: 1; /* 使游戏详情部分占据剩余空间 */
  display: flex;
  align-items: center;
}

.game-name {
  font-size: 30px;
  color: #469B50;
  flex: 1;
}

.submission-count {
  font-size: 0.9em;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.28);
  border-radius: 5px;
  min-width: 166px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin: 5px;
  white-space: nowrap;
}

.submission {
  display: flex;
  flex-direction: column;
}
</style>
