<template>
  <div className="rules-page">
    <h1>2024年第一届“数码庄园杯”互联网行业企业职工数字体育大赛开始报名</h1>
    <p>
      2024年“数码庄园杯”互联网行业企业职工数字体育大赛，一场专为职工量身定制的盛会，今日正式拉开序幕！我们致力于促进职工的身心健康，增强团队的凝聚力与竞争力，为广大职工提供一个展示个人技能、拓展人脉关系、享受电子竞技运动乐趣的绝佳平台。
    </p>
    <p>
      在这场激动人心的赛事中，您将有机会与来自各地的顶尖选手一较高下，体验电子竞技的激情与挑战。不仅如此，本次比赛的丰厚奖品由小米公司特约赞助，确保每一位参与者都能享受到前所未有的竞技体验和奖励。
    </p>
    <p>现在，就让我们共同期待这场电子竞技的盛宴，一起见证团队精神的闪耀和个人才华的绽放。立即报名，加入这场属于全体职工的电子竞技狂欢，让我们在“数字庄园”中，共同追逐荣耀与梦想！</p>
    <p>报名现已开启，期待您的参与！</p>

    <h2>一、组织单位</h2>
    <h3>指导单位</h3>
    <p>中国互联网协会电子竞技工作委员会</p>
    <h3>主办单位</h3>
    <p>华竞联合（北京）科技有限公司</p>
    <h3>协办单位</h3>
    <p>北京垚淼商务服务有限公司</p>

    <h2>二、比赛项目</h2>
    <p>王者荣耀</p>

    <h2>三、参赛条件</h2>
    <ul>
      <li>参赛运动员必须年满18周岁，并且是企业的在职员工。</li>
      <li>每位运动员只能代表其所属企业的一个参赛队伍参加比赛。</li>
      <li>同一企业可以组织多支队伍报名参加本次大赛。</li>
      <li>每支队伍至少需要五名运动员和一名领队。领队可以由队伍中的运动员或队长兼任。领队负责通过官方报名通道填写并提交队伍的详细信息，完成线上报名流程。</li>
      <li>报名不收取任何费用，线下决赛的参赛队伍需要自行承担差旅费用。如果无法参加线下赛，我们将按照规则顺延名额给其他队伍。</li>
    </ul>

    <h2>四、赛程安排</h2>
    <ul>
      <li>报名时间：10月18日——11月01日</li>
      <li>线上海选：11月06日——11月27日</li>
      <li>线下决赛：11月30日——12月01日</li>
    </ul>

    <h2>五、赛事规则</h2>
    <h3>线上海选：</h3>
    <ul>
      <li>1、随机匹配对手；</li>
      <li>2、在指定时间进行比赛，迟到或无法完成比赛均视为比赛失败；</li>
      <li>3、领队在裁判的监督下进行选边；</li>
      <li>4、单场决胜负；</li>
      <li>5、最终16支优胜队伍晋级线下决赛。</li>
    </ul>

    <h3>线下决赛：</h3>
    <ul>
      <li>1、随机抽取十六强阶段的顺位规则；</li>
      <li>2、在现场裁判的指引下，在指定时间和指定场地进行比赛，迟到或无法完成比赛均视为比赛失败；</li>
      <li>3、决赛为BO5，其他均为BO3。</li>
      <p>赛制基本按照全民赛事惯例，详细规则在报名群由组委会工作人员发放。</p>
    </ul>

    <h2>六、决赛场地</h2>
    <p>亦庄数码庄园（北京市亦庄经济开发区地盛西路1号）</p>
    <p>在决赛期间，园区将为参赛者和观众提供一系列周到的服务。我们诚挚邀请北京及周边地区的朋友们届时亲临园区，与我们共享这个精彩绝伦的周末！</p>

    <h2>七、特约奖品</h2>
    <p>本次比赛为非盈利性质，所有奖品均由小米集团特别赞助提供。</p>
    <ul>
      <li>冠军奖品：米家全效空气净化器6个，总价值24000元。</li>
      <li>亚军奖品：小米全自动智能门锁pro 6个，总价值13200元。</li>
      <li>季军奖品：Xiaomi HomeWiFi三频Mesh路由器6个，总价值7800元。</li>
      <li>个人荣誉奖品：Xiaomi Sound Pro智能音箱6个，总价值5400元。</li>
    </ul>

    <h2>八、报名渠道</h2>
    <p>请通过扫描下方二维码进行报名。若在报名过程中遇到
      任何问题或需要帮助，欢迎随时联系我们的组委会工作人员，
      具体联系方式在报名页面，他们将为您提供必要的支持和指
      导。我们期待您的参与，共同享受这场电子竞技的盛宴！</p>
    <div className="qr-code">
      <!-- 这里放置二维码的图片 -->
      <img src="@/assets/bisai_xiaochengxu.png" alt="报名二维码" class="img-baoming"/>
      <img src="@/assets/bisai_erweima.png" alt="报名二维码" class="img-baoming"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "RulesPage",
};
</script>

<style scoped>
.rules-page {
  padding: 0px 200px 200px 200px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

.rules-page h1{
  font-size: 30px;
}
.rules-page h2 {
  color: #333;
  font-size: 25px;
}
.rules-page h3 {
  color: #333;
  font-size: 22px;
}

.rules-page p {
  text-indent: 2em; /* 段落首行缩进 */
  font-size: 22px;
}
/*.rules-page ul {
  list-style-type: none;
  padding-left: 0;
}*/
.rules-page h1 {
  color: #333;
  text-align: center; /* 标题居中 */
  margin: 50px;
}
.rules-page li {
  margin-bottom: 10px;
  font-size: 22px;
}

.qr-code {
  margin-top: 20px;
  text-align: center;
}

.qr-code img {
  max-width: 200px;
  height: auto;
}
.img-baoming{
  width: 300px;
  height: 300px;
  margin: 100px;
}
</style>
